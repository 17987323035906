import { render, staticRenderFns } from "./LabelWrapper.vue?vue&type=template&id=597c8f3c"
import script from "./LabelWrapper.vue?vue&type=script&lang=js"
export * from "./LabelWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports