<template>
  <div class="config-app">
    <h2 class="app-title">Definições da Interface do Software</h2>
    <p class="subtitle">Permite você configurar alguns quesitos da experiência do usuário e como o sistema deve se comportar.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper label="Abrir relatórios em uma nova janela">
          <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('sistema.ux.relatorio-nova-janela').value" />
        </app-label-wrapper>

        <app-label-wrapper>
          <div class="m-t">
            <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
        </app-label-wrapper>
      </div>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"

export default {
  name: "ConfigInterface",
  mixins: [AppMixin],
  components: {USelect},
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        }
      ]
    }
  }
}
</script>
