import {listTipoEtapasFull} from '@/domain/processos/services/produtividade'
import window from './window/window.js'
import {number_format as numberFormat} from "@/utils/phpjs";

export default {
  pageTitle: 'Etapas de Produtividade',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'codigo',
      required: true,
      label: 'Código Único',
      align: 'left',
      field: 'codigo',
      sortable: true,
      parser: function (props) {
        return props.codigo
      }
    },
    {
      name: 'comissao',
      required: true,
      label: 'Recebe Comissão',
      align: 'left',
      field: 'comissao',
      sortable: true,
      parser: function (props) {
        return props.comissao ? 'Sim' : 'Não'
      }
    },
    {
      name: 'valor',
      required: true,
      label: 'Valor',
      align: 'left',
      field: 'valor',
      sortable: true,
      parser: function (props) {
        return 'R$ ' + numberFormat(props.valorComissao, 2, ',', '.')
      }
    },
    {
      name: 'diaLancamento',
      required: true,
      label: 'Dia do Lançamento no Financeiro',
      align: 'left',
      field: 'diaLancamento',
      sortable: true,
      parser: function (props) {
        return props.diaLancamento
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listTipoEtapasFull,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
