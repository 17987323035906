<template>
  <div class="app-input-row">
    <app-label>
      <slot name="label">{{label}}</slot>
    </app-label>
    <slot name="label-content">
    <app-label-content>
      <slot />
    </app-label-content>
    </slot>
  </div>
</template>

<script>
import AppLabelContent from "@/components/globalconfig/components/include/Content"
import AppLabel from "@/components/globalconfig/components/include/Label"

export default {
  name: "AppLabelWrapper",
  props: ['label'],
  components: {
    AppLabelContent,
    AppLabel
  }
}
</script>
