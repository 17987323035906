import {uid} from "uloc-vue";

export default function (
    id
) {
    let window = typeof id !== 'undefined' ? `relatorioDinamico.${id}` : 'relatorioDinamico-' + uid()
    let css = window.replace(/\./g, '-')
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Relatório Dinâmico',
        width: '1000',
        height: '800',
        minHeight: '500px',
        backdrop: false,
        clickOutside: false,
        maximize: false,
        move: false,
        fullscreen: true,
        disableFixedSize: true,
        windowClass: 'bem-window ' + css,
        contentClass: 'overflow-hidden',
        props: {
            id: id,
            $router: this.$router,
            router: this.$router
        }
    }, () => import('./RelatorioDinamicoWindow.vue'))
        .then((wid) => {
            console.log(wid)
            setTimeout(() => {
                document.querySelector('.bem-window.' + css).classList.add('opened')
            }, 20)
            this.$uloc.window.listen(wid, {
                updated: (wid, val) => {
                    console.log('Update payment event')
                    this.load && this.load()
                    this.update && this.update(val)
                },
                created: (wid, val) => {
                    console.log('Create payment event')
                    this.load && this.load()
                    this.update && this.update(val)
                }
            })
        }) // return wid
}
