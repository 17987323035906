<template>
  <list-default ref="list" :resolver="resolver" @updateList="updateList" />
</template>

<script>
import ListDefault from '../ListDefault'
import resolver from './resolver'

export default {
  name: 'ListStatusTarefa',
  components: {ListDefault},
  computed: {
    resolver () {
      return resolver
    }
  },
  methods: {
    updateList () {
      console.log('List is updating...')
      this.$refs.list.load()
    }
  }
}
</script>
