<template>
  <div class="config-app">
    <h2 class="app-title">Formulários</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de formulários.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">

        Nada para configurar.

        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configurações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>
      </div>
      <list-categorias v-if="active === 'categorias'" class="app-input-container" />
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect, UCheckbox, UInput} from "uloc-vue"
import ListCategorias from "@/components/cadastro/components/categoriasformularios/List"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'

export default {
  name: "ConfigForms",
  inject: ['config'],
  components: {
    ListCategorias,
    //UInput,
    //USelect,
    //UCheckbox,
    //CodeEditor,
    //Collapse
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico',
    }
  },
  mounted() {
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'categorias',
          label: 'Categorias'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
