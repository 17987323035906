<script>
import {listRelatoriosDinamicos as list, deleteRelatorio} from "@/domain/relatorios/services"
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import listOptions from '@/components/vistoria/windows/listOptions'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import TaskListMenu from "@/components/sltarefas/components/lista/list-menu"
import ContextMenuTaskItem from "@/components/sltarefas/components/lista/context-menu"
import relatorioWindow from "components/globalconfig/components/apps/relatorios/windows/relatorioWindow";

const listName = 'sl.relatorios.custom'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Código', name: 'code', active: true, sortable: true, ordering: 1},
  {label: 'Entidade', name: 'entidade', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
], 0)

let filters = {
  id: null,
  search: null,
  nome: null,
  code: null,
  entidade: null,
  status: null
}

export default {
  name: 'RelatoriosCustomizados',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
    parseProps: {required: false}
  },
  mixins: [],
  components: {
    ContextMenuTaskItem,
    TaskListMenu,
    DateInput,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField
  },
  data() {
    const filtros = JSON.parse(JSON.stringify(filters))
    filtros.tipoData = 'createdAt'
    return {
      listStorage: listStorage,
      listType: 'a',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      },
      stats: null,
      counts: null
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  beforeMount() {
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(null, pagination.rowsPerPage, pagination.page, filtros)
          .then((response) => {
            const data = response.data
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo(t = null) {
      console.log('New...')
      this.relatorioWindow(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.relatorioWindow(id)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir relatorio-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteRelatorio(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Conta excluída com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    relatorioWindow
  },
  meta: {
    title: 'Relatórios Customizados',
    name: 'Relatórios Customizados'
  }
}
</script>

<template>
  <div class="vistoria-container wrapper-md">
    <e-row class="m-b-lg">
      <e-col style="max-width: 350px">
        <!--<resumo v-if="!isEmbedComponent" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
        <!-- @TODO: Fazer isto funcionar de acordo o filtro de relacao -->
      </e-col>
      <!--        <e-col class="flex items-start justify-end col-grow-1">
                <div class="saldo-contas font-12 font-avenir text-grey-7 flex column m-l-xxl">
                  <div class="at-title m-b">PERÍODO</div>
                  <ul class="menu-alfabeto bold flex column" style="margin-top: 0; margin-left: -8px">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>
                </div>
              </e-col>-->
      <e-col class="flex items-center justify-center col-grow-1">
        <!--          <ul class="menu-alfabeto bold m-l-xxl">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>-->
        <!-- <fin-filtros-adicionais class="m-r" :filters="table.filters" :stats="stats" />
        <filtro-conta :stats="stats" />
        <filtro-periodo :stats="stats" @mudarPeriodo="buscaPorPeriodo" @addsub="addsubPeriodo" :periodos="filtroPeriodos" :filters="table.filters" class="m-l-lg" />-->
      </e-col>
      <e-col class="justify-end flex">
        <!--<fin-saldo-contas @new="(t) => novo(t)" :type="listType" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
        <!--          <u-btn @click="windowVistoria" no-caps color="green" class="app-btn font-12 no-border-radius-right">Registrar vistoria</u-btn>-->
        <!-- @TODO -->
        <u-btn @click="novo" class="sl-btn" color="positive" no-caps label="Criar Relatório Dinâmico"/>
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
        <div class="end font-14 bg-white flex-zero flex no-wrap">
          <e-col style="min-width: 320px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input placeholder="Nome, código, entidade, ID..." shortkey="F3" v-model="table.filters.search"
                         @keydown.enter="pesquisar"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)"
                        v-model="table.filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)"
                        v-model="table.filters.data2"/>
          </e-col>
        </div>
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table erp-table-options table-fin"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
              :props="props" :class="{'u-table-item-destac': props.row.isToday}">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td class="td-limit l-130" key="nome" :props="props">
            {{ props.row.nome }}
          </u-td>
          <u-td key="code" :props="props">
            {{ props.row.code }}
          </u-td>
          <u-td key="entidade" :props="props">
            {{ props.row.entidade }}
          </u-td>
          <u-td class="" key="status" :props="props">
            <div v-if="props.row.status === 0" class="text-orange">Aguardando aprovação</div>
            <div v-if="props.row.status === 1" class="text-green">Ativo</div>
            <div v-if="props.row.status === 99" class="text-negative">Reprovado</div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <task-list-menu @abrir="abrir(props.row.id)"
                                @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
          <context-menu-task-item
              @abrir="abrir(props.row.id)"
              @excluir="excluir(props.row.id)"
          />
        </u-tr>
      </u-table>
    </div>
  </div>
</template>
