<template>
  <div class="config-app">
    <h2 class="app-title">Definições do Caller</h2>
    <p class="subtitle">Selecione os métodos de chamada padrão para você e sua empresa, configure o Caller e gerencie sua assinatura.</p>

    <app-tab v-if="typeof this.config.config[configName] !== 'undefined'" :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'caller'" class="app-input-container">
        <div v-if="typeof config.config['apps.caller.voip'] !== 'undefined'">
          VoIP integrado com {{config.config['apps.caller.voip'].value}}
        </div>
        <div v-else>
         Integração Caller não disponível em seu software, solicite configuração ao suporte.
        </div>
      </div>
      <div v-if="active === 'pessoal'" class="app-input-container">
        <app-label-wrapper style="width: 100% !important; flex: 1" label="Aplicativo de chamada padrão">
          <template v-slot:label-content>
          <div class="flex justify-between no-wrap col-grow">
            <u-select :options="callerOptions" class="app-input" style="flex: 1" hide-underline v-model="configVal.value" />
            <u-input class="app-input m-l-sm" hide-underline v-model="configVal.value" style="width: 180px"/>
          </div>
          </template>
        </app-label-wrapper>
        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="text-left col-grow">
              <p class="app-legend m-t-xs">Clicar em qualquer número no ERP vai iniciar uma ligação usando seu método selecionado.</p>
              <p class="app-legend"><a>Leia mais aqui</a>, ou vá para o <a>marketplace</a> para navegar pelas integrações de telefone.</p>
            </div>
          </template>
        </app-label-wrapper>

        <app-label-wrapper>
          <template v-slot:label-content>
          <div class="m-t col-grow">
            <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configurações" no-caps color="green" class="app-btn" />
          </div>
          </template>
        </app-label-wrapper>
      </div>
    </app-tab>
    <div v-else>
      Ausência nas configurações do Caller. Informe ao administrador do sistema e solicite correção.
    </div>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"

export default {
  name: "ConfigCaller",
  inject: ['config'],
  mixins: [AppMixin],
  components: {USelect},
  data() {
    return {
      active: 'caller'
    }
  },
  computed: {
    configName () {
      return 'apps.caller'
    },
    configVal (){
      return this.config.config[this.configName]
    },
    callerOptions () {
      let options = this.getExtra()
      options = options['options']
      console.log(options)
      const extra = {
        label: 'Personalizar',
        value: options.find(o => o.value == this.configVal.value) ? '' : this.configVal.value
      }
      options.push(extra)
      return options
    },
    menu() {
      return [
        {
          name: 'caller',
          label: 'Caller'
        },
        {
          name: 'pessoal',
          label: 'Pessoal'
        }
      ]
    }
  },
  methods: {
    getExtra () {
      let extra = JSON.parse(this.configVal.extra)
      return extra
    }
  }
}
</script>
