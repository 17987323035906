<template>
<div>
  <h2 class="dtitle-diviser">Checklist automático para novos cadastros de Bens</h2>
  <div class="m-t">
    <table class="sl-table-config">
      <thead>
      <th>Descrição</th>
      <th>Parente</th>
      <th>Tipo Bem</th>
      <th>Evento</th>
      <th>Seções</th>
      <th class="text-center">Impeditivo <i class="fal fa-info-circle"><u-tooltip :offset="[10,10]">Se a não conclusão do item impede o bem prosseguir para o evento de venda</u-tooltip></i></th>
      <th class="text-center">Status</th>
      <th class="text-center"></th>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td><u-fake-loader width="100%" /></td>
        <td></td>
        <td><u-fake-loader width="100%" /></td>
        <td><u-fake-loader width="100%" /></td>
        <td><u-fake-loader width="100%" /></td>
      </tr>
      <tr v-on:dblclick="abrir(model)" v-for="model in list" :key="model.id">
        <td style="width: 50%">
          <div class="font-bold">{{model.nome}}</div>
          <div style="color: #848484">{{model.descricao}}</div>
        </td>
        <td>
          <div v-if="model.parent">{{model.parent.nome}}</div>
        </td>
        <td><div v-if="model.tiposBem && model.tiposBem.length">{{model.tiposBem.map(t => t.nome).join(', ')}}</div></td>
        <td><div v-if="model.evento">{{model.evento.nome}}</div></td>
        <td><div v-if="model.secoes && model.secoes.length">{{model.secoes.map(t => t.nome).join(', ')}}</div></td>
        <td class="text-center"><svg v-if="model.impeditivo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17" viewBox="0 0 16 17">
          <image id="Imagem_50" data-name="Imagem 50" width="16" height="17" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
        </svg>
        </td>
        <td class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <rect id="Retângulo_1011" data-name="Retângulo 1011" width="12" height="12" rx="6" :fill="model.active ? '#3baf55' : '#EB0000'"/>
        </svg>
        </td>
        <td>
          <e-btn-table-options>
            <menu-options>
              <ul>
                <menu-options-item close label="Abrir" @click="abrir(model)" />
                <menu-options-item close label="Excluir" @click="excluir(model)" label-class="text-negative" />
              </ul>
            </menu-options>
          </e-btn-table-options>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="m-t">
      <u-btn @click="novo" class="sl-btn" color="green" no-caps label="Adicionar novo item do checklist" />
    </div>
  </div>
</div>
</template>

<script>
import appWindow from "./window"
import {list, deleteConfig} from "@/domain/bem/services/configChecklist"
import {UFakeLoader, UTooltip} from "uloc-vue"
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

export default {
  name: "BemConfigChecklist",
  data () {
    return {
      loading: false,
      list: null
    }
  },
  components: {
    UFakeLoader,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem,
    UTooltip
  },
  mounted() {
    this.load()
  },
  methods: {
    novo () {
      this.appWindow(null)
    },
    abrir (model) {
      this.appWindow(model.id || model)
    },
    appWindow,
    load() {
      this.loading = true
      const filtros = []
      list(1000, 1, filtros.join('&'))
          .then(response => {
            this.list = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    excluir(model) {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteConfig(model.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    }
  }
}
</script>
