<script>
/* eslint-disable */
import {UInput, USelect} from 'uloc-vue'
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import {
  deleteGlobalconfig,
  list,
  newGlobalconfig,
  updateGlobalconfig,
  setup
} from '@/domain/globalconfig/services'
import ERow from '../../layout/components/Row'
import ECol from '../../layout/components/Col'
import Collapse from "@/components/globalconfig/components/include/Collapse"
import configApps from "@/components/globalconfig/components/apps/appList"
import Vue from "vue";

for (let app in configApps) {
  Vue.component(configApps[app].name, configApps[app].component)
}

let initRoute = null

const mockGlobalconfig = {
  name: null,
  value: null,
  description: null,
  extra: null
}

export default {
  provide: function () {
    return {
      config: this
    }
  },
  name: 'Globalconfigs',
  inject: ['page'],
  mixins: [],
  components: {Collapse, ECol, ERow, ErpInput, ErpSField, UInput, USelect},
  data() {
    if (this.$route.name.startsWith('config.')) {
      let path = this.$route.path.split('/')
      this.initRoute = path[path.length - 1]
    }
    return {
      active: this.initRoute || 'empresa',
      avancado: false,
      configs: [],
      config: {},
      servicos: [],
      loading: false
    }
  },
  computed: {
    servicosNomes () {
      return Array.isArray(this.servicos) ? this.servicos.map(s => s.servico) : []
    },
    menu() {
      return [
        {
          title: 'Configurações Globais',
          items: [
            {
              name: 'empresa',
              title: 'Empresa',
              route: 'config.empresa'
            },
            {
              name: 'bem',
              title: 'Bem',
              route: 'config.bem'
            },
            {
              name: 'lance',
              title: 'Lance',
              route: 'config.lance'
            },
            {
              name: 'clientes',
              title: 'Clientes',
              route: 'config.clientes'
            },
            {
              name: 'comunicacao',
              title: 'Comunicação',
              route: 'config.comunicacao'
            },
            {
              name: 'robo',
              title: 'Robô',
              route: 'config.robo'
            },
            {
              name: 'financeiro',
              title: 'Financeiro',
              route: 'config.financeiro'
            },
            {
              name: 'forms',
              title: 'Formulários',
              route: 'config.forms'
            },
            {
              name: 'processos',
              title: 'Processos',
              route: 'config.processos'
            },
            {
              name: 'tarefas',
              title: 'Tarefas',
              route: 'config.tarefas'
            },
            {
              name: 'projetos',
              title: 'Projetos',
              route: 'config.projetos'
            },
            {
              name: 'marketing',
              title: 'Marketing',
              route: 'config.marketing'
            },
            {
              name: 'relatorios',
              title: 'Relatórios',
              route: 'config.relatorios'
            },
            {
              name: 'interface',
              title: 'Interface',
              route: 'config.interface'
            }
          ]
        },
        {
          title: 'Ferramentas',
          items: [
            {
              name: 'caller',
              icon: 'call',
              title: 'Caller',
              route: 'config.caller'
            },
            {
              name: 'apps',
              icon: 'smartphone',
              title: 'Apps Móveis',
              route: 'config.apps'
            }
          ]
        },
        {
          title: 'Integrações',
          items: [
            {
              name: 'integra-comitentes',
              title: 'Comitentes',
              route: 'config.integra-comitentes'
            },
            {
              name: 'integra-imovelweb',
              title: 'Imóvel Web',
              route: 'config.integra-imovelweb'
            },
            {
              name: 'integra-resale',
              title: 'Resale',
              route: 'config.integra-resale'
            },
            {
              name: 'integra-icarta',
              title: 'iCarta Pro',
              route: 'config.integra-icarta'
            },
            {
              name: 'integra-santander-imoveis',
              title: 'Santander Imóveis',
              route: 'config.integra-santander-imoveis'
            },
            {
              name: 'integra-robo-processos',
              title: 'Robô Processos',
              route: 'config.integra-robo-processos'
            },
            {
              name: 'integra-detranPB',
              title: 'Detran Paraíba',
              route: 'config.integra-detranPB'
            },
            {
              name: 'integra-grupoPorto',
              title: 'Grupo Porto',
              route: 'config.integra-grupoPorto'
            },
            {
              name: 'integra-youse',
              title: 'Youse Seguradora',
              route: 'config.integra-youse'
            }
          ]
        },
        {
          title: 'ERP',
          items: [
            {
              name: 'redefinir',
              title: 'Redefinir Configurações',
              route: 'config.redefinir'
            }
          ]
        }
      ]
    }
  },
  beforeMount() {

  },
  mounted() {
    this.page.setActive('sistema')
    document.querySelector('.u-erp-layout-container').classList.add('app-globalconfig')
    this.load()
  },
  beforeDestroy() {
    document.querySelector('.u-erp-layout-container').classList.remove('app-globalconfig')
  },
  watch: {
/*    active (v) {
    }*/
  },
  methods: {
    load() {
      list(1000, 1, '&erp=1')
          .then(response => {
            console.log(response.data)
            let configs = {}
            response.data.result.slice().map(r => {
              configs[r.name] = {
                ...r,
                extra: r.extra
              }
            })
            this.config = configs
            this.servicos = response.data.servicos
            this.configs = response.data.result.map(r => {
              return {
                ...r,
                extra: r.extra
              }
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    novo() {
      if (!Array.isArray(this.configs)) {
        this.configs = []
      }
      this.configs.push(JSON.parse(JSON.stringify(mockGlobalconfig)))
    },
    salvar() {
      this.loading = true
      let config = this.avancado ? this.configs : Object.values(this.config)
      let _data = config.slice().map(r => {
        return {
          ...r,
          extra: JSON.stringify(r.extra)
        }
      })
      updateGlobalconfig(_data)
          .then(response => {
            this.loading = false
            this.$uloc.notify({
              color: 'positive',
              message: `Sistema atualizado com sucesso!`
            })
            this.load()
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    },
    excluir(sistema) {
      this.$uloc.dialog({
        title: `Tem certeza que deseja excluir a configuração ${sistema.nome} ?`,
        message: 'Digite EXCLUIR e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'negative'
      }).then(data => {
        if (data.toUpperCase() !== 'EXCLUIR') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Exclusão não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'negative',
          message: `Excluindo configuração...`
        })
        deleteGlobalconfig(sistema.name)
            .then(response => {
              this.$uloc.notify({
                color: 'positive',
                message: `Configuração deletada com sucesso!`
              })
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    setup() {
      this.$uloc.dialog({
        title: 'Tem certeza que deseja executar o setup básico ? Isso pode impactar severamente toda a aplicação e causar perda de informações personalizadas.',
        message: 'Digite INICIAR SETUP e clique para confirmar:',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        color: 'secondary'
      }).then(data => {
        if (data.toUpperCase() !== 'INICIAR SETUP') {
          this.$uloc.notify({
            color: 'negative',
            message: `Você não confirmou a operação. Atualização não efetuada.`
          })
          return
        }
        this.$uloc.notify({
          color: 'primary',
          message: `Executando o setup...`
        })
        setup()
            .then(response => {
              this.$uloc.notify({
                color: 'positive',
                message: `Sistema configurado com sucesso!`
              })
              this.load()
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    c(name, isBool = false, permission = null) {
      if (typeof this.config[name] === 'undefined') {
        const config = {
          name: name,
          value: isBool ? false : '',
          description: '...',
          extra: null,
          permission: permission
        }
        this.configs.push(config)
        this.config[name] = config
      }
      if (isBool) {
        this.config[name].value = Number(this.config[name].value) !== 0
      }
      return this.config[name]
    },
    showMenu (m) {
      if (typeof document !== 'undefined') {
        document.title = m.title
      }
      if (m.route) {
        this.$router.push({name: m.route, exact: true})
      }
      this.active = m.name
    },
    hasService (name) {
      return this.servicos && this.servicosNomes.includes(name)
    }
  },
  meta: {
    title: 'Configuração Global',
    name: 'Globalconfigs'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap globalconfigs-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="global-config-base">
          <div class="global-config-apps">
            <div v-for="(m, i) in menu" :key="i">
              <div class="gc-title">{{ m.title }}</div>
              <a @click="showMenu(item)" v-for="(item, i2) in m.items" :key="'item-' + i2" class="gc-item" :class="{active: item.name === active}">
                <u-icon :name="item.icon" v-if="item.icon" />
                <div>{{ item.title }}</div>
              </a>
            </div>
          </div>
          <component @salvar="salvar" v-bind:is="active" :c="c" class="global-config-container"></component>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
