import http from '../../../utils/services/http'

/*export const secoes = {
    1: {nome: 'Principal', codigo: 'principal'},
    2: {nome: 'Característica', codigo: 'caracteristica'},
    3: {nome: 'Checklist', codigo: 'checklist'},
    4: {nome: 'Gestão', codigo: 'gestao'},
    5: {nome: 'Extra', codigo: 'extra'},
}*/

export const list = (limit, page, filtros) => {
    let url = '/api/bem/campos?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
    let url = `/api/bem/campos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newCampo = (data) => {
    let url = `/api/bem/campos`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateCampo = (campo, method = 'PUT') => {
    let url = `/api/bem/campos/${campo.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, campo)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteCampo = (id) => {
    let url = `/api/bem/campos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
