import {UInput} from "uloc-vue"
import AppLabelContent from "@/components/globalconfig/components/include/Content"
import AppLabel from "@/components/globalconfig/components/include/Label"
import AppLabelWrapper from "@/components/globalconfig/components/include/LabelWrapper"
import AppTab from "@/components/globalconfig/components/include/Tab"
import ERow from "@/components/layout/components/Row"
export default {
    props: ['c'],
    inject: ['config'],
    components: {AppLabelContent, AppLabel, AppLabelWrapper, AppTab, UInput, ERow},
    beforeMount () {
        if (this.$route.hash) {
            this.active = this.$route.hash.replace('#', '')
        }
    },
    watch: {
        '$route' () {
            this.$nextTick(() => {
                if (this.$route.hash) {
                    this.active = this.$route.hash.replace('#', '')
                }
            })
        }
    }
}
