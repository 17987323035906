<template>
  <div class="config-app">
    <h2 class="app-title">Configurações de Lance</h2>
    <p class="subtitle">Permite configurar como o sistema de lances deve funcionar.</p>

    <div class="app-input-container">
      <app-label-wrapper label="Casas decimais do lance">
        <u-input class="app-input" type="number" hide-underline v-model="c('lance.casa_decimal').value"/>
      </app-label-wrapper>

      <app-label-wrapper label="Multa por desistência de arremate">
        <u-input class="app-input" hide-underline v-model="c('arremate.desistencia.multa').value" suffix="%"/>
        <p class="app-legend m-t-xs">Caso o arrematante desista de uma arrematação, o sistema financeiro gera uma multa. Deixe 0 para não aplicar multa.</p>
      </app-label-wrapper>

      <app-label-wrapper label="Permitir lance com cadastro sem aprovação">
        <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado').value" />
        <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, o arrematante ao se cadastrar poderá efetuar um ou mais lances (de acordo o limite configurado abaixo) sem necessidade de aprovação do cadastro.</p>
      </app-label-wrapper>
      <app-label-wrapper label="Limite de lances sem aprovação">
        <u-input class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado-limite-lances').value"/>
      </app-label-wrapper>

      <app-label-wrapper label="Limite de incremento para lance sem aprovação">
        <u-input class="app-input" hide-underline v-model="c('arrematante.lance.cadastro-nao-aprovado-limite-incremento').value"/>
        <p class="app-legend m-t-xs">Coloque 0 para nenhum</p>
      </app-label-wrapper>

      <app-label-wrapper label="Permitir disputa de lances com parcelados com lances à vista">
        <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.permitirDisputaParceladoXAvista').value" />
        <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lances parcelados poderão disputar com lances à vista.</p>
      </app-label-wrapper>

      <app-label-wrapper label="Permitir lance à vista igualar lance parcelado">
        <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('arrematante.permitirLanceAvistaIgualarParcelado').value" />
        <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lances à vista poderão igualar lances parcelados.</p>
      </app-label-wrapper>

      <app-label-wrapper label="Prejudicar automaticamente sublotes em lances no lote principal">
        <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('lance.prejudicarSublotesLancePrincipal').value" />
        <p class="app-legend m-t-xs m-b-none">Se <strong>Sim</strong>, lance no lote principal automaticamente atualiza o status dos sublotes para Prejudicado.</p>
      </app-label-wrapper>

      <app-label-wrapper label="Modo do Lance Automático">
        <u-select :options="[{label: 'Priorizar até o valor configurado', value: '0'},{label: 'Priorizar somente até o último incremento do valor configurado', value: '1'}]" class="app-input" hide-underline v-model="c('lance.priorizarAutomatico').value" />
        <p class="app-legend m-t-xs m-b-none"><strong>Priorizar até o valor configurado</strong>, se o arrematante configurar, por exemplo, R$ 10.000,00, e o último lance for dele de R$ 9.800,00, e alguém efetuar um incremento que chegue à R$ 10.000,00, o sistema rejeita o lance do arrematante e efetua um novo lance do arrematante que configurou o lance automático com este limite.</p>
      </app-label-wrapper>

      <app-label-wrapper label="Filtrar somente compradores no PDA">
        <u-select :options="[{label: 'Sim', value: '1'},{label: 'Não', value: '0'}]" class="app-input" hide-underline v-model="c('pda.filtrarSomenteCompradores').value" />
      </app-label-wrapper>

      <app-label-wrapper>
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
        </div>
      </app-label-wrapper>
    </div>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"

export default {
  name: "ConfigLance",
  mixins: [AppMixin],
  components: {USelect},
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'pagamento',
          label: 'Pagamento'
        }
      ]
    }
  }
}
</script>
