<template>
  <div class="config-app">
    <h2 class="app-title">Resale</h2>
    <p class="subtitle">Permite integração.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'credenciais'" class="app-input-container">
        <app-label-wrapper label="Ativar integração com Resale">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('integra.resale').value" />
          <!-- <p class="app-legend m-t-xs"></p> -->
        </app-label-wrapper>
        <app-label-wrapper label="Ambiente">
          <u-select :options="[{label: 'Homologação', value: '0'},{label: 'Produção', value: '1'}]" class="app-input" hide-underline v-model="c('integra.resale.env').value" />
          <!-- <p class="app-legend m-t-xs"></p> -->
        </app-label-wrapper>
        <app-label-wrapper label="Chave de acesso para consulta de Imóveis">
          <u-input hide-underline v-model="c('integra.resale.x-api-key').value" class="app-input" />
        </app-label-wrapper>
      </div>
      <app-label-wrapper>
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" :loading="config.loading" no-caps color="green" class="app-btn" />
        </div>
      </app-label-wrapper>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'

export default {
  name: "ConfigResale",
  mixins: [AppMixin],
  inject: ['config'],
  components: {
    // Collapse,
    USelect,
    // CodeEditor
  },
  data() {
    return {
      active: 'credenciais'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'credenciais',
          label: 'Credenciais'
        },
        // {
        //   name: 'avancado',
        //   label: 'Avançado'
        // }
      ]
    }
  }
}
</script>
