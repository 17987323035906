<template>
  <div class="config-app">
    <h2 class="app-title">Relatórios</h2>
    <p class="subtitle">Permite você configurar as preferências e customizar os relatórios do sistema.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <relatorios-customizados v-if="active === 'basico'" class="app-input-container" />
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import RelatoriosCustomizados from "@/components/globalconfig/components/apps/relatorios/RelatoriosCustomizados"

export default {
  name: "ConfigRelatorios",
  inject: ['config'],
  components: {
    RelatoriosCustomizados
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico',
      tmp: {
        fcbPageView: 'PageView',
        fcbViewContent: 'ViewContent',
      }
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Relatórios Customizados'
        }
      ]
    }
  }
}
</script>
