import {list} from '@/domain/bem/services/campos'
import window from './window/window.js'

export default {
  pageTitle: 'Campos de Bens',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Campo',
      align: 'left',
      field: 'name',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'tipos',
      required: true,
      label: 'Tipos de Bens',
      align: 'left',
      field: 'description',
      sortable: true,
      parser: function (props) {
        return Array.isArray(props.tiposBem) ? props.tiposBem.map(t => t.nome).join(', ') : '-'
      }
    },
    {
      name: 'secao',
      required: true,
      label: 'Seção',
      align: 'left',
      field: 'secao',
      sortable: true,
      parser: function (props) {
        return props.secao ? props.secao.nome : '-'
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
