<template>
  <div class="config-app">
    <h2 class="app-title">Tarefas</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de processos.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper style="width: 100% !important; flex: 1" label="Padrão de listagem das tarefas">
          <template v-slot:label-content>
            <div class="flex justify-between no-wrap col-grow">
              <u-select :options="listOptions" class="app-input" style="flex: 1" hide-underline v-model="config.c(listConfigName, false, 'user').value" />
            </div>
          </template>
        </app-label-wrapper>

        <app-label-wrapper style="width: 100% !important; flex: 1" class="m-t" label="Mostrar abas">
          <template v-slot:label-content>
            <div class="flex justify-between no-wrap col-grow">
              <div class="m-t-sm">
                <div>
                  <u-checkbox v-model="config.c('task.mostrarAbaParaMim', true, 'user').value" /> Para mim
                </div>
                <div>
                  <u-checkbox v-model="config.c('task.mostrarAbaCriadasPorMim', true, 'user').value" /> Criadas Por Mim
                </div>
              </div>
            </div>
          </template>
        </app-label-wrapper>

        <app-label-wrapper style="width: 100% !important; flex: 1" class="m-t" label="Criação de Tarefa">
          <template v-slot:label-content>
            <div class="flex justify-between no-wrap col-grow">
              <div class="m-t-sm">
                <div>
                  <u-checkbox v-model="config.c('task.obrigarSelecionarResponsavel', true, 'user').value" /> Tornar obrigatório a seleção do um responsável
                </div>
                <div>
                  <u-checkbox v-model="config.c('task.obrigarSelecionarCliente', true, 'user').value" /> Tornar obrigatório a seleção do cliente
                </div>
<!--                <div>
                  <u-checkbox v-model="config.c('task.obrigarSelecionarProcesso', true, 'user').value" /> Tornar obrigatório a seleção do processo
                </div>-->
              </div>
            </div>
          </template>
        </app-label-wrapper>


        <app-label-wrapper>
          <template v-slot:label-content>
            <div class="m-t col-grow">
              <u-btn @click="$emit('salvar')" :loading="config.loading" label="Salvar configurações" no-caps color="green" class="app-btn" />
            </div>
          </template>
        </app-label-wrapper>
      </div>
      <list v-if="active === 'status'" class="app-input-container" />
      <list-type v-if="active === 'tipo'" class="app-input-container" />
      <list-prioridades-tarefa v-if="active === 'priority'" class="app-input-container" />
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import List from "@/components/cadastro/components/statustarefa/List"
import ListType from "@/components/cadastro/components/tipostarefa/List"
import ListPrioridadesTarefa from "@/components/cadastro/components/tarefaprioridades/List"
import {USelect, UCheckbox} from "uloc-vue"

export default {
  name: "ConfigTarefas",
  inject: ['config'],
  components: {
    ListPrioridadesTarefa,
    List,
    ListType,
    USelect,
    UCheckbox
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    listConfigName () {
      return 'task.list'
    },
    listConfigVal (){
      return this.config.config[this.listConfigName] || {value: 1}
    },
    listOptions () {
      return [
        {
          label: 'Normal, listando todas as tarefas ativas (Padrão)',
          value: "1"
        },
        {
          label: 'Listar somente tarefas do dia e vencidas',
          value: "2"
        },
        {
          label: 'Listar somente tarefas do dia e vencidas do usuário logado',
          value: "3"
        }
      ]
    },
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'status',
          label: 'Status de Tarefa'
        },
        {
          name: 'tipo',
          label: 'Tipos de Tarefa'
        },
        {
          name: 'priority',
          label: 'Prioridades de Tarefa'
        }
      ]
    }
  }
}
</script>
