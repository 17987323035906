<template>
  <list-default @clearFilters="clearFilters" :parseFilters="parseFilters" ref="list" :resolver="resolver" @updateList="updateList">
    <template v-slot:filters>
    <form id="filter" class="m-t-sm">
      <e-row class="def-m-r">

        <e-col style="max-width: 110px; min-width: 110px">
          <erp-s-field
              view="tl"
              label="Pesquisa inteligente"
          >
            <erp-input @keydown.enter="$refs.list.pesquisar" shortkey="F2" v-model="filters.busca" />
          </erp-s-field>
        </e-col>

<!--        <e-col>
          <erp-s-field
              label="Tipo de bem:"
          >
            <erp-select v-model="filters.tipo" placeholder="Qualquer" :options="tiposFiltered"
                        :loading="loadingTipos">
              <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.label}}</span>
              </template>
            </erp-select>
          </erp-s-field>
        </e-col>-->

        <e-col style="max-width: 110px; min-width: 110px">
          <erp-s-field
              view="tl"
              label="Status"
          >
            <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                        v-model="filters.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </form>
    </template>
  </list-default>
</template>

<script>
import ListDefault from '../ListDefault'
import resolver from './resolver'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import {listTiposBem} from "@/domain/cadastro/services"
import {treeCheck} from "@/components/cadastro/components/tiposbem/helpers/treeCheck"

export default {
  name: 'ListChecklistSecaoBem',
  components: {
    ListDefault,
    ERow,
    ECol,
    ErpInput,
    ErpSField,
    ErpSelect
  },
  computed: {
    resolver () {
      return resolver
    },
    tiposFiltered () {
      return this.treeCheck(this.tipos)
    }
  },
  data() {
    return {
      loadingTipos: false,
      tipos: [],
      filters: {
        busca: null,
        tipo: null,
        active: [true]
      }
    }
  },
  mounted () {
    this.loadingTipos = true
    listTiposBem(200, 1, null, true)
        .then(({data}) => {
          this.tipos = data
          this.loadingTipos = false
        })
        .catch(error => {
          console.error(error)
        })
  },
  methods: {
    parseFilters (pagination) {
      let search = this.filters.busca || ''
      let active = this.filters.active.join(',')
      const f = []
      this.filters.tipo && f.push(`tiposBem=${this.filters.tipo}`)
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${search}&active=${active}&${f.join('&')}`)
      return filtros
    },
    treeCheck: treeCheck,
    updateList () {
      console.log('List is updating...')
      this.$refs.list.load()
    },
    clearFilters () {
      this.filters = {
          busca: null,
          tipo: null,
          active: [true]
      }
    }
  }
}
</script>
