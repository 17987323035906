import {list} from '@/domain/processos/services/produtividadeComissoes'
import window from './window/window.js'
import {number_format as numberFormat} from "@/utils/phpjs";

export default {
  pageTitle: 'Comissões de Produtividade',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.pessoa.name
      }
    },
    {
      name: 'etapa',
      required: true,
      label: 'Etapa',
      align: 'left',
      field: 'etapa',
      sortable: true,
      parser: function (props) {
        return props.etapa.nome
      }
    },
    {
      name: 'comissao',
      required: true,
      label: 'Recebe Comissão?',
      align: 'left',
      field: 'comissao',
      sortable: true,
      parser: function (props) {
        return props.comissao ? 'Sim' : 'Não'
      }
    },
    {
      name: 'valor',
      required: true,
      label: 'Valor',
      align: 'left',
      field: 'valor',
      sortable: true,
      parser: function (props) {
        return 'R$ ' + numberFormat(props.valor, 2, ',', '.')
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
