<template>
  <div class="config-app">
    <h2 class="app-title">Santander Imóveis</h2>
    <p class="subtitle">Permite integração.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
        <app-label-wrapper label="Ativar integração com Santander Imóveis">
          <u-select :options="[{label: 'Não', value: '0'},{label: 'Sim', value: '1'}]" class="app-input" hide-underline v-model="c('integra.santander-imoveis').value" />
<!--          <p class="app-legend m-t-xs"></p>-->
        </app-label-wrapper>
        <app-label-wrapper label="Credenciais para API">
          <collapse title="Editar credenciais" :collapse="false">
            <code-editor v-model="c('integra.santander-imoveis.credenciais').value" :languages="[['javascript']]" :display_language="false" class="source-code" width="100%" height="200px" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
          </collapse>
        </app-label-wrapper>
      </div>
      <app-label-wrapper>
        <div class="m-t">
          <u-btn @click="$emit('salvar')" label="Salvar configurações" no-caps color="green" class="app-btn" />
        </div>
      </app-label-wrapper>
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import {USelect} from "uloc-vue"
import CodeEditor from 'simple-code-editor'
import Collapse from 'components/globalconfig/components/include/Collapse'

export default {
  name: "ConfigSantanderImoveis",
  mixins: [AppMixin],
  components: {
    Collapse,
    USelect,
    CodeEditor
  },
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Configurações'
        }
      ]
    }
  }
}
</script>
