<template>
  <list-default @clearFilters="clearFilters" :parseFilters="parseFilters" ref="list" :resolver="resolver" @updateList="updateList">
    <template v-slot:filters>
    <form id="filter" class="m-t-sm">
      <e-row class="def-m-r">

        <e-col style="max-width: 110px; min-width: 110px">
          <erp-s-field
              view="tl"
              label="Pesquisa inteligente"
          >
            <erp-input @keydown.enter="$refs.list.pesquisar" shortkey="F2" v-model="filters.busca" />
          </erp-s-field>
        </e-col>


        <e-col style="max-width: 110px; min-width: 110px">
          <erp-s-field
              view="tl"
              label="Status"
          >
            <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]" multiple
                        v-model="filters.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </form>
    </template>
  </list-default>
</template>

<script>
import ListDefault from '../ListDefault'
import resolver from './resolver'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"

export default {
  name: 'ListCategoriasForms',
  components: {
    ListDefault,
    ERow,
    ECol,
    ErpInput,
    ErpSField,
    ErpSelect
  },
  computed: {
    resolver () {
      return resolver
    }
  },
  data() {
    return {
      filters: {
        busca: null,
        active: [true]
      }
    }
  },
  mounted () {
  },
  methods: {
    parseFilters (pagination) {
      let search = this.filters.busca || ''
      let active = this.filters.active.join(',')
      const f = []
      // this.filters.tipo && f.push(`tiposBem=${this.filters.tipo}`)
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${search}&active=${active}&${f.join('&')}`)
      return filtros
    },
    updateList () {
      console.log('List is updating...')
      this.$refs.list.load()
    },
    clearFilters () {
      this.filters = {
          busca: null,
          active: [true]
      }
    }
  }
}
</script>
