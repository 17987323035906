<template>
  <div class="config-app">
    <h2 class="app-title">Definições do Comitente</h2>
<!--    <p class="subtitle"></p>-->

    <div class="m-t-lg">
      Indisponível no momento.
    </div>
  </div>
</template>

<script>
import AppMixin from './appMixin'

export default {
  name: "ConfigComitente",
  inject: ['config'],
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        }
      ]
    }
  }
}
</script>
