import http from '../../../utils/services/http'

export const newProdutividadeComissao = (data) => {
  let url = `/api/processos/produtividadeComissoes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/processos/produtividadeComissoes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateProdutividadeComissao = (id, data) => {
  let url = `/api/processos/produtividadeComissoes/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros) => {
  let url = '/api/processos/produtividadeComissoes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
