<template>
  <div class="app-tab">
    <div class="tab-menu">
      <a @click="$emit('changeTab', item)" v-for="(item, key) in menu" :key="key" :class="{active: item.name === active}">{{ item.label }}</a>
    </div>
    <div class="tab-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTab",
  props: {
    active: String,
    menu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>
