import {listClassificacaoProcessos} from '../../../../domain/cadastro/services'
import window from './window/windowAcessorio'

export default {
  pageTitle: 'Classificação de Processos',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'codigo',
      required: true,
      label: 'Código Único',
      align: 'left',
      field: 'codigo',
      sortable: true,
      parser: function (props) {
        return props.codigo
      }
    },{
      name: 'descricao',
      required: true,
      label: 'Descrição',
      align: 'left',
      field: 'descricao',
      sortable: true,
      parser: function (props) {
        return props.descricao
      }
    },
    {
      name: 'status',
      required: true,
      label: 'Status(s)',
      align: 'left',
      field: 'status',
      class: 'td-limit l-130',
      sortable: true,
      parser: function (props) {
        let status = []
        Array.isArray(props.status) && props.status.map(v => {
          status.push(v.nome)
        })
        status = status.join(', ')
        if (props.statusExcecao && Array.isArray(props.statusExcecao) && props.statusExcecao.length > 0) {
          let excecao = []
          props.statusExcecao.map(v => {
            excecao.push(v.nome)
          })
          status += ' exceto ' + excecao.join(', ')
        }
        return status
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listClassificacaoProcessos,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
